<!-- 支付结果 -->
<template>
    <div class="container flex flex-1 align-c justify-c">
        <div class="pay-result-container flex-column align-c">
            <div class="flex justify-c align-c">
                <img class="mr-10" src="../../assets/images/pay_success.png">
                <p class="font-18 font-bold color-orange">恭喜您！订单支付成功</p>
            </div>
            <div class="color-666 order-info">
                <p>订单编号：{{order.ordersn}}</p>
                <p class="mt-10">支付金额：{{(order.payprice + order.dispatchprice).toFixed(2)}}</p>
            </div>
            <ul class="flex align-c justify-c">
                <router-link :to="{name: 'orderDetail', query: {id: order.id}}" tag="li" class="btn">订单详情</router-link>
                <router-link :to="{name: 'home'}" tag="li" class="btn">继续购物</router-link>
            </ul>
        </div>
    </div>
</template>

<script>
export default {
    name: 'PayResult',
    components: {},

    data() {
        return {
            orderId: this.$route.query.orderId || '',
            order: {}
        };
    },

    created() {
        this.getOrder()
    },

    methods: {
        getOrder() {
             this.$https.get(this.$api.orderInfo,{
                orderId: this.orderId,
                id: '',
                iskuaidi:'',
                shopId: '',
                number: Math.random()
            })
            .then((res) =>{
                this.order = res.data
            })
        }
    }
}
</script>
<style lang='scss' scoped>
    .pay-result-container{
        width: 900px;
        height: 400px;
        padding-top: 60px;
        box-shadow: 0 0 5px #ccc;
    }
    .order-info{
        min-width: 265px;
        margin-top: 30px;
    }
    .btn{
        width: 140px;
        height: 42px;
        margin-top: 50px;
        margin-right: 40px;
        line-height: 42px;
        text-align: center;
        color: var(--orange-color);
        cursor: pointer;
        border-radius: 5px;
        border: 1px solid var(--orange-color);
        &:nth-child(2){
            margin-right: 0;
            color: #fff;
            border: none;
            background: linear-gradient(90deg, #FD5C02, #ff9458);
        }
    }
</style>